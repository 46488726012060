<template>
  <section>
    <div v-if="isHelicopter">
      <b-button
        class="mb-1"
        :to="{ name: 'trip-view', params: { trip_id: $route.params.trip_id } }"
      >
        {{ $t('provider.back_to_timeline') }}
      </b-button>
      <b-button
        class="ml-1 mb-1"
        :to="{ name: 'TimelineStepTransferHelicopterOrganizations', params: pick($route.params, 'trip_id', 'step_id', 'event_id') }"
      >
        {{ $t('provider.back_to_catalogue') }}
      </b-button>
    </div>

    <!--TRIP RESUME-->
    <b-overlay :show="!tripData">
      <trip-view :trip-data="tripData" />
    </b-overlay>

    <!--ACTION-->
    <b-row v-if="!isHelicopter">
      <b-col class="d-flex justify-content-end mb-1" cols="12">
        <b-button
          variant="primary"
          class="mr-1"
          :to="{
            name: 'shop-checkout',
            params: {
              trip_id: this.$route.params.trip_id,
              step_id: this.$route.params.step_id,
              event_id: this.$route.params.event_id,
              airport_id: this.$route.params.airport_id,
            },
          }"
        >
          <feather-icon class="mr-50" icon="ShoppingCartIcon" />
          {{ $t('trip.shop.go_to_cart') }}
        </b-button>
        <b-button
          v-if="currentNav !== 'hangar' && currentNav !== 'fuel'"
          v-ripple.400
          :to="{
            name: 'provider-list',
            params: {
              trip_id: this.$route.params.trip_id,
              event_id: this.$route.params.event_id,
              airport_id: this.$route.params.airport_id,
            },
          }"
          variant="primary"
        >
          <font-awesome-icon class="mr-50" icon="exchange-alt" />
          {{ $t('provider.change_provider') }}
        </b-button>
        <b-button
            v-if="currentNav === 'hangar'"
            v-ripple.400
            :to="{
            name: 'hangar',
            params: {
              trip_id: this.$route.params.trip_id,
              event_id: this.$route.params.event_id
            },
          }"
            variant="primary"
        >
          <font-awesome-icon class="mr-50" icon="exchange-alt" />
          {{ $t('provider.change_provider') }}
        </b-button>
        <b-button
            v-if="currentNav === 'fuel'"
            v-ripple.400
            :to="{
            name: 'fuel',
            params: {
              trip_id: this.$route.params.trip_id,
              event_id: this.$route.params.event_id
            },
          }"
            variant="primary"
        >
          <font-awesome-icon class="mr-50" icon="exchange-alt" />
          {{ $t('provider.change_provider') }}
        </b-button>
      </b-col>
    </b-row>

    <!--PROVIDER COMPONENTS-->
    <section>
      <provider-profile-header :provider="provider" :current-nav="currentNav" :is-helicopter="isHelicopter" @current-nav="handleCurrentNav($event)" />

      <!--SERVICES-->
      <section v-if="!isHelicopter" v-show="currentNav === 'services'">
        <MandatoryPackage @update:mandatory-package="mandatorySelected = $event" />
        <section>
          <shop-flight v-if="tripStep.className === 'TripFlight'" :provider="provider" :mandatory-selected="mandatorySelected" />
          <shop-transfer v-else />
        </section>
      </section>
      <!--HANGAR-->
      <section v-if="!isHelicopter" v-show="currentNav === 'hangar'">
        <section>
          <hangar-list v-if="tripStep.className === 'TripFlight'" :provider="provider" />
        </section>
      </section>
      <!--FUEL-->
      <section v-if="!isHelicopter" v-show="currentNav === 'fuel'">
        <section>
          <fuel-list v-if="tripStep.className === 'TripFlight' && provider.id" :provider="provider" />
        </section>
      </section>
      <!--DESCRIPTION-->
      <section v-if="currentNav === 'description'">
        <b-card class="mt-2">
          <b-row class="my-2">
              <b-col v-if="provider.banners && provider.banners.length > 0" cols="6" style="max-height: 400px" >
                <div v-if="provider.banners.length === 1" class="h-100 d-flex align-items-center justify-content-center">
                  <div class="product-img h-100">
                    <b-img
                      :src="provider.banners[0].bannerUrl"
                      fluid
                    />
                  </div>
                </div>
                <div v-else class="h-100 d-flex align-items-center justify-content-center">
                    <swiper class="swiper-wrapper h-100" :options="swiperOptions" :modules="modules" navigation :pagination="{ clickable: true }">
                      <swiper-slide v-for="file in provider.banners" :key="file.id" class="swiper-slide h-100">
                        <div class="product-img h-100">
                          <b-img
                            :src="file.bannerUrl"
                            fluid
                          />
                        </div>
                      </swiper-slide>
                    </swiper>
                    <div class="swiper-button-next" slot="button-next"></div>
                    <div class="swiper-button-prev" slot="button-prev"></div>
                </div>
              </b-col>
              <b-col v-if="provider.description" cols="6">
                  <div>
                      <b-card-text>
                          <!-- eslint-disable-next-line vue/no-v-html -->
                          <div class="ql-editor" v-html="provider.description" />
                      </b-card-text>
                  </div>
              </b-col>
          </b-row>
        </b-card>

        <b-row class="match-height mt-2">
          <b-col lg="4" sm="12">
            <ProviderProfileInformations :provider="provider" />
          </b-col>

          <b-col lg="4" sm="12">
            <ProviderProfileWorkingHours v-if="provider" :provider="provider" />
          </b-col>

          <b-col lg="4" sm="12">
            <ProviderProfileMap :location="[{ position: { lat: provider.defaultAddress.latitude, lng: provider.defaultAddress.longitude, } }]" />
          </b-col>
        </b-row>

        <b-row v-if="isHelicopter" class="mt-2">
            <b-col cols="12">
                <b-card>
                    <b-row>
                        <b-col cols="6">
                            <b-card>
                                <b-card-header class="pb-50">
                                    <h4>
                                        {{ $t('provider.helicopter.bases') }}
                                    </h4>
                                </b-card-header>
                                <b-card-body>
                                    <div v-for="base in provider.bases">
                                        <h5>{{ base.cityBase.city }}</h5>
                                        <ul>
                                            <li v-for="depositZone in base.depositZones">{{ depositZone.inlineAddress }}</li>
                                        </ul>
                                    </div>
                                </b-card-body>
                            </b-card>
                        </b-col>
                        <b-col cols="6">
                            <b-card>
                                <b-card-header class="pb-50">
                                    <h4>
                                        {{ $t('provider.helicopter.destinations') }}
                                    </h4>
                                </b-card-header>
                                <b-card-body>
                                    <div v-for="destination in provider.destinations">
                                        <h5>{{ destination.address.city }}</h5>
                                        <ul>
                                            <li v-for="depositZone in destination.depositZones">{{ depositZone.inlineAddress }}</li>
                                        </ul>
                                    </div>
                                </b-card-body>
                            </b-card>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
          </b-row>

        <b-row class="match-height mt-2">
          <b-col lg="12" sm="12">
            <ProviderProfileFacilities :provider="provider" />
          </b-col>
        </b-row>
      </section>
      <!--HELICOPTER-->
      <section v-if="isHelicopter" v-show="currentNav === 'helicopters'">
        <section>
          <b-row>
            <b-col md="9" class="d-flex align-items-center">
              <h1>{{ $t('provider.helicopters_fleet') }}</h1>
            </b-col>
            <b-col md="3">
              <b-form-group :label="$t('provider.bases')" label-for="bases">
                <v-select
                  v-model="selectedBase"
                  input-id="bases"
                  :options="basesOptions"
                  :reduce="option => option.id"
                  :get-option-label="option => option.cityBaseName"
                  :clearable="false"
                  @option:selected="APIFetchTripStepEventOnQuoteHelicopter()"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-overlay :show="servicesLoader" :spinner-small="false">
            <b-row>
              <b-col v-for="service in services" :key="service.id" md="3">
                <b-card class="ecommerce-card" no-body>
                  <div class="text-center pt-0">
                      <b-img
                        :alt="`${service.name[userLang]}-${service.id}`"
                        :src="resolveProductImgScrc(service)"
                        class="shop-img"
                      />
                  </div>

                  <!-- Product Details -->
                  <b-card-body>
                      <h4 class="item-name text-underline">
                          <b-link
                            :to="{
                              name: 'shop-service-helicopter-details',
                              params: {
                                trip_id: $route.params.trip_id,
                                step_id: $route.params.step_id,
                                event_id: $route.params.event_id,
                                required_organization_id: provider.id,
                                destination_id: service.mainVariant.id,
                                type: 'on-quote'
                            } }"
                          >
                              {{ service.name | trans }}
                          </b-link>
                      </h4>
                      <h5 class="font-weight-bold">{{ $t('service.technical_informations.title') }}</h5>
                      <div v-if="service.mainVariant && service.mainVariant.variantAttributes.length">
                          <div v-for="attribute in service.mainVariant.variantAttributes" :key="attribute.id">
                              <p v-if="attribute.inputValues">{{ attribute.attribute.name | trans }} : {{ attribute.inputValues | trans }}</p>
                              <p v-if="attribute.selectValue">
                                  <b-link :href="attribute.selectValue.iconFileUrl" target="_blank">
                                      <img v-if="attribute.selectValue.iconFileUrl" height="25px" width="25px" :src="attribute.selectValue.iconFileUrl" :alt="attribute.selectValue.name">
                                  </b-link>
                                  {{ attribute.attribute.name | trans }} : {{ attribute.selectValue.name | trans }}
                              </p>
                              <div v-if="attribute.choiceValues">
                                  <p v-for="choice in attribute.choiceValues">
                                      <b-link :href="choice.iconFileUrl" target="_blank">
                                          <img v-if="choice.iconFileUrl" height="25px" width="25px" :src="choice.iconFileUrl" :alt="choice.name">
                                      </b-link>
                                      {{ choice.name | trans }}
                                  </p>
                              </div>
                          </div>
                      </div>
                  </b-card-body>

                  <!-- Product Actions -->
                  <div class="item-options">
                      <b-button
                              block
                              class="btn-cart"
                              variant="primary"
                              :to="{
                                name: 'shop-service-helicopter-details',
                                params: {
                                  trip_id: $route.params.trip_id,
                                  step_id: $route.params.step_id,
                                  event_id: $route.params.event_id,
                                  required_organization_id: provider.id,
                                  destination_id: service.mainVariant.id,
                                  type: 'on-quote'
                                } }"
                      >
                          <span>{{ $t('trip.shop.view_details') }}</span>
                      </b-button>
                    </div>
                </b-card>
              </b-col>
            </b-row>
            <!--PAGINATION-->
            <div class="mx-2 mb-2">
              <app-data-table-bottom
                :page="page"
                :per-page="12"
                :total-count="servicesMeta.totalItems"
                table-name="services"
                @pagination="APIFetchTripStepEventOnQuoteHelicopter($event)"
              />
            </div>
          </b-overlay>
        </section>
      </section>
    </section>
  </section>
</template>

<script>
import { omit, pick } from 'lodash'

import AppDataTableBottom from '@/components/AppDataTableBottom.vue'
import TripView from '@/views/trip/trip-view/TripView.vue'
import ShopFlight from '@/views/shop/shop/flight/ShopList.vue'
import ShopTransfer from '@/views/shop/shop/transfer/ShopTransfer.vue'
import HangarList from '@/views/provider/hangar/HangarList.vue'
import FuelList from '@/views/provider/fuel/FuelList.vue'
import ProviderProfileHeader from '@/views/provider/profile/ProviderProfileHeader.vue'
import ProviderProfileInformations from '@/views/provider/profile/ProviderProfileInformations.vue'
import ProviderProfileMap from '@/views/provider/profile/ProviderProfileMap.vue'
import ProviderProfileFacilities from '@/views/provider/profile/ProviderProfileFacilities.vue'
import MandatoryPackage from '@/views/provider/mandatoryPackage/MandatoryPackage.vue'
import { fetchOrganizationRequest } from '@/request/globalApi/requests/organizationRequests'
import ProviderProfileWorkingHours from '@/views/provider/profile/ProviderProfileWorkingHours.vue'
import {
  fetchTripStepEventOnQuoteHelicopterRequest,
  fetchTripStepEventOrganizationBasesRequest,
} from '@/request/globalApi/requests/tripStepEventRequests'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { Navigation, Pagination } from 'swiper'
import 'swiper/css/swiper.min.css'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'

export default {
  name: 'ProviderDetails',

  components: {
    Swiper,
    SwiperSlide,
    AppDataTableBottom,
    ProviderProfileWorkingHours,
    TripView,
    MandatoryPackage,
    ShopFlight,
    ShopTransfer,
    HangarList,
    FuelList,
    ProviderProfileHeader,
    ProviderProfileInformations,
    ProviderProfileMap,
    ProviderProfileFacilities,
  },

  data() {
    return {
      currentNav: 'services',
      tripData: {},
      provider: null,
      servicesLoader: true,
      services: [],
      page: 1,
      mandatorySelected: null,
      servicesMeta: {
        firstPage: 1,
        lastPage: 1,
        nextPage: null,
        previousPage: null,
        totalItems: 0,
      },
      basesOptions: [{ id: null, cityBaseName: this.$t('provider.all_bases') }],
      selectedBase: null,
      swiperOptions: {
        centeredSlides: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    }
  },
  computed: {
    userLang() {
      return this.$store.state.appConfig.lang
    },
    isHelicopter() {
      return !this.$route.params.airport_id
    },
    tripStep() {
      if (!this.tripData.tripSteps) return {}
      const stepId = Number(this.$route.params.step_id)
      return this.tripData.tripSteps.find(item => item.id === stepId)
    },
  },
  created() {
    if (this.isHelicopter) this.currentNav = 'helicopters'
  },
  mounted() {
    if (this.$route.query.currentNav) {
      this.currentNav = this.$route.query.currentNav
    }

    this.APIFetchOrganization()
    this.APIFetchTrip()
    if (this.isHelicopter) {
      this.APIFetchTripStepEventOnQuoteHelicopter()
      this.APIFetchOrganizationBases()
    }
  },
  methods: {
    pick,
    async APIFetchTripStepEventOnQuoteHelicopter(page = null) {
      this.page = page || 1
      this.servicesLoader = true
      fetchTripStepEventOnQuoteHelicopterRequest(this.$route.params.event_id, this.$route.params.provider_id, { baseId: this.selectedBase, organizationCatalog: true, page }).then(({ data }) => {
        this.services = data.services
        this.servicesMeta = omit(data, 'services')
      }).finally(() => {
        this.servicesLoader = false
      })
    },
    async APIFetchTrip() {
      const tripId = Number(this.$route.params.trip_id)
      try {
        this.tripData = await this.$store.dispatch('trip/fetchTrip', { tripId, forced: false })
      } catch (error) {
        console.warn(error)
      }
    },
    APIFetchOrganization() {
      const organizationId = Number(this.$route.params.provider_id)
      fetchOrganizationRequest(organizationId).then(r => {
        this.provider = r.data.organization
        this.provider.id = organizationId
      })
    },
    APIFetchOrganizationBases() {
      const organizationId = Number(this.$route.params.provider_id)
      const eventId = Number(this.$route.params.event_id)
      fetchTripStepEventOrganizationBasesRequest(eventId, organizationId).then(({ data }) => {
        for (const base in data.bases) {
          if (Object.prototype.hasOwnProperty.call(data.bases, base)) {
            this.basesOptions.push({id: data.bases[base].cityBaseName, cityBaseName: data.bases[base].cityBaseName})
          }
        }
      })
    },
    handleCurrentNav(nav) {
      this.currentNav = nav
    },
    resolveProductImgScrc(product) {
      if (product?.mainVariant?.files?.length) return product.mainVariant.files[0]?.fileUrls[300]
      if (product?.mainCategory?.iconFileUrl) return product.mainCategory.iconFileUrl
      return require('@/assets/images/logo/logo.png')
    },
  },
  setup() {
    const swiperOptions = {
      slidesPerView: 1,
      spaceBetween: 0,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    }

    return {
      swiperOptions,
      modules: [Navigation, Pagination],
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-ecommerce-details.scss';

.swiper-button-next, .swiper-button-prev {
  width: 40px;
  height: 40px;
  background-color: rgba(236, 236, 236, 0.75) !important;
  border-radius: 50% !important;
}

.swiper-slide {
  width: 100%;
  height: 400px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 35.2px;
  background-color: var(--theme-banner);
  color: var(--text-secondary);
}
.product-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>

<style lang="scss">
@import '@core/scss/vue/pages/page-profile.scss';
@import "~quill/dist/quill.snow.css";


.shop-img {
  width: 100%;
  height: 8rem;
  object-fit: contain;
}
</style>
