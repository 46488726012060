<template>
  <section>
    <b-card
      v-if="provider"
      :img-src="provider.bannerFileUrl ? provider.bannerFileUrl : require('@/assets/images/banner/airplane.jpg')"
      alt="cover photo"
      body-class="p-0"
      class="profile-header mb-2"
      img-top
    >
      <!-- profile picture -->
      <div class="position-relative">
        <div class="profile-img-container d-flex align-items-center">
          <div class="profile-img">
            <b-img :src="provider.logoFileUrl ? provider.logoFileUrl : require('@/assets/images/logo/logo.png')" alt="profile photo" fluid rounded />
          </div>

          <!-- profile title -->
          <div class="profile-title ml-3">
            <h2>{{ provider.legalName }}</h2>
            <p>{{ provider.commercialName }}</p>
          </div>
        </div>
      </div>

      <!-- profile navbar -->
      <div class="profile-header-nav m-0 border-top">
        <b-navbar toggleable="md" type="light">
          <!-- toggle button -->
          <b-navbar-toggle class="ml-auto" target="nav-text-collapse">
            <feather-icon icon="AlignJustifyIcon" size="21" />
          </b-navbar-toggle>

          <!-- collapse -->
          <b-collapse id="nav-text-collapse" is-nav>
            <b-tabs class="profile-tabs m-0" nav-class="mb-0" pills>
              <template #tabs-start>
                <b-nav-item :active="currentNav === 'description'" class="font-weight-bold" role="presentation" @click="updateCurrentNav('description')">
                  <span class="d-none d-md-block">{{ $t('provider.description') }}</span>
                  <feather-icon class="d-block d-md-none" icon="InfoIcon" />
                </b-nav-item>

                <b-nav-item v-if="!isHelicopter" :active="currentNav === 'services'" class="font-weight-bold" role="presentation" @click="updateCurrentNav('services')">
                  <span class="d-none d-md-block">{{ $t('provider.ground_handling_service') }}</span>
                  <feather-icon class="d-block d-md-none" icon="RssIcon" />
                </b-nav-item>

                <b-nav-item v-if="!isHelicopter && provider.hangar" :active="currentNav === 'hangar'" class="font-weight-bold" role="presentation" @click="updateCurrentNav('hangar')">
                  <span class="d-none d-md-block">{{ $t('provider.hangar_and_aircraft_ramps') }}</span>
                  <feather-icon class="d-block d-md-none" icon="RssIcon" />
                </b-nav-item>

                <b-nav-item v-if="!isHelicopter && provider.fuel" :active="currentNav === 'fuel'" class="font-weight-bold" role="presentation" @click="updateCurrentNav('fuel')">
                  <span class="d-none d-md-block">{{ $t('provider.fuel') }}</span>
                  <feather-icon class="d-block d-md-none" icon="RssIcon" />
                </b-nav-item>

                <b-nav-item v-if="isHelicopter" :active="currentNav === 'helicopters'" class="font-weight-bold" role="presentation" @click="updateCurrentNav('helicopters')">
                  <span class="d-none d-md-block">{{ $t('provider.helicopters') }}</span>
                  <feather-icon class="d-block d-md-none" icon="InfoIcon" />
                </b-nav-item>

                <!-- <b-nav-item v-if="false" :active="currentNav === 'rules'" class="font-weight-bold" role="presentation" @click="updateCurrentNav('rules')">
                  <span class="d-none d-md-block">{{ $t('provider.rules_condition') }}</span>
                  <feather-icon class="d-block d-md-none" icon="ImageIcon" />
                </b-nav-item> -->
              </template>

              <!-- edit buttons -->
              <template #tabs-end>
                <b-button class="ml-auto" @click="isContactSidebarActive = !isContactSidebarActive">
                  <font-awesome-icon class="mr-50" icon="comments" />
                  <span>{{ $t('action.contact') }}</span>
                </b-button>
              </template>
            </b-tabs>
          </b-collapse>
        </b-navbar>
      </div>
    </b-card>

    <sidebar-contact-provider v-if="provider" :is-contact-sidebar-active.sync="isContactSidebarActive" :provider-id="provider.id" />
  </section>
</template>

<script>
import SidebarContactProvider from '@/views/provider/sidebar/SidebarContactProvider.vue'

export default {
  name: 'ProviderProfileHeader',

  components: { SidebarContactProvider },

  props: {
    isHelicopter: {
      type: Boolean,
      default: false,
    },
    provider: {
      type: Object,
      default: null,
    },
    currentNav: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isContactSidebarActive: false,
    }
  },
  methods: {
    updateCurrentNav(nav) {
      this.$emit('current-nav', nav)
    },
  },
}
</script>
<style>
.profile-img {
  width: 108px;
  height: 108px;
  display: flex;
  align-items: center;
  border: 1px solid #f6f6f6;
  background-color: #fff;
}

.profile-header img {
  max-height: 300px;
  object-fit: cover;
}
</style>
