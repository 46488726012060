var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.products.length)?_c('b-card',{attrs:{"id":"pricing-plan"}},[_c('div',{staticClass:"text-center pb-2"},[_c('h1',{staticClass:"mt-50"},[_vm._v(" "+_vm._s(_vm.$t('trip.shop.mandatory_packages'))+" ")]),_c('p',{staticClass:"d-inline"},[_vm._v(" "+_vm._s(_vm.$t('trip.shop.mandatory_packages_notice'))+" ")])]),_c('swiper',{staticClass:"swiper-responsive-breakpoints mb-2 pricing-card",attrs:{"options":_vm.swiperOptions}},[_vm._l((_vm.products),function(productEntity,index){return _c('swiper-slide',{key:index,staticClass:"pl-3"},[_c('b-card',{staticClass:"ecommerce-card popular m-1 p-2",attrs:{"no-body":""}},[_c('div',{staticClass:"d-flex flex-column h-100"},[_c('h3',{staticClass:"d-flex align-items-center justify-content-center"},[_c('b-form-radio-group',{attrs:{"button-variant":"outline-primary"},model:{value:(_vm.mandatorySelectedIndex),callback:function ($$v) {_vm.mandatorySelectedIndex=$$v},expression:"mandatorySelectedIndex"}},[_c('b-form-radio',{attrs:{"value":index}},[_c('h3',{staticClass:"text-underline"},[_c('b-link',{attrs:{"to":{
                      name: 'shop-service-details',
                      params: {
                        product_id: productEntity.product.id,
                        provider_id: _vm.$route.params.provider_id,
                        trip_id: _vm.$route.params.trip_id,
                        step_id: _vm.$route.params.step_id,
                        event_id: _vm.$route.params.event_id,
                        airport_id: _vm.$route.params.airport_id,
                      },
                    }}},[_vm._v(" "+_vm._s(_vm._f("trans")(productEntity.product.name))+" ")])],1)])],1)],1),_c('div',{staticClass:"annual-plan d-flex align-items-center justify-content-center"},[(productEntity.product.priceExcludingTaxes)?_c('div',{staticClass:"mt-1 pricing-basic-value font-weight-bolder text-primary"},[_c('span',[_vm._v(_vm._s(_vm.$t('trip.cost.total_before_tax'))+" : ")]),_c('span',[_vm._v(_vm._s(_vm._f("priceFormat")(productEntity.product.priceExcludingTaxes)))])]):_c('div',{staticClass:"mt-1 justify-content-center"},[_c('span',{staticClass:"pricing-basic-value font-weight-bolder text-primary"},[_vm._v("Price on quote")])])]),_c('b-list-group',{staticClass:"list-group-circle text-left"},_vm._l((productEntity.product.variants),function(variant){return _c('b-list-group-item',{key:variant.id},[_vm._v(" "+_vm._s(_vm._f("trans")(variant.productName))+" ")])}),1),(_vm.mandatorySelectedIndex === index || productEntity.selected)?_c('div',{staticClass:"m-auto pt-2"},[(!productEntity.selected)?_c('b-button',{staticClass:"btn-cart",on:{"click":function($event){return _vm.patchCart(index)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"ShoppingCartIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t('trip.shop.add_to_cart')))])],1):_c('b-button',{staticClass:"btn-cart",attrs:{"to":{ name: 'shop-checkout', params: _vm.$route.params }}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"ShoppingCartIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t('trip.shop.action.view_in_cart')))])],1)],1):_vm._e()],1)])],1)}),_c('div',{staticClass:"swiper-button-next",attrs:{"slot":"button-next"},slot:"button-next"}),_c('div',{staticClass:"swiper-button-prev",attrs:{"slot":"button-prev"},slot:"button-prev"})],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }