<template>
  <b-card v-if="products.length" id="pricing-plan">
    <!-- title text and switch button -->
    <div class="text-center pb-2">
      <h1 class="mt-50">
        {{ $t('trip.shop.mandatory_packages') }}
      </h1>
      <p class="d-inline">
        {{ $t('trip.shop.mandatory_packages_notice') }}
      </p>
    </div>

    <!-- pricing plan cards -->
    <swiper class="swiper-responsive-breakpoints mb-2 pricing-card" :options="swiperOptions">
      <swiper-slide v-for="(productEntity, index) in products" :key="index" class="pl-3">
        <b-card class="ecommerce-card popular m-1 p-2" no-body>
          <div class="d-flex flex-column h-100">
            <h3 class="d-flex align-items-center justify-content-center">
              <b-form-radio-group v-model="mandatorySelectedIndex" button-variant="outline-primary">
                <b-form-radio :value="index">
                  <h3 class="text-underline">
                    <b-link
                      :to="{
                        name: 'shop-service-details',
                        params: {
                          product_id: productEntity.product.id,
                          provider_id: $route.params.provider_id,
                          trip_id: $route.params.trip_id,
                          step_id: $route.params.step_id,
                          event_id: $route.params.event_id,
                          airport_id: $route.params.airport_id,
                        },
                      }"
                    >
                      {{ productEntity.product.name | trans }}
                    </b-link>
                  </h3>
                </b-form-radio>
              </b-form-radio-group>
            </h3>

            <div class="annual-plan d-flex align-items-center justify-content-center">
              <div v-if="productEntity.product.priceExcludingTaxes" class="mt-1 pricing-basic-value font-weight-bolder text-primary">
                <span>{{ $t('trip.cost.total_before_tax') }} : </span>
                <span>{{ productEntity.product.priceExcludingTaxes | priceFormat }}</span>
              </div>
              <div v-else class="mt-1 justify-content-center">
                <span class="pricing-basic-value font-weight-bolder text-primary">Price on quote</span>
              </div>
            </div>

            <!-- plan benefit -->
            <b-list-group class="list-group-circle text-left">
              <b-list-group-item v-for="variant in productEntity.product.variants" :key="variant.id">
                {{ variant.productName | trans }}
              </b-list-group-item>
            </b-list-group>

            <div v-if="mandatorySelectedIndex === index || productEntity.selected" class="m-auto pt-2">
              <b-button v-if="!productEntity.selected" class="btn-cart" @click="patchCart(index)">
                <feather-icon icon="ShoppingCartIcon" class="mr-50" />
                <span>{{ $t('trip.shop.add_to_cart') }}</span>
              </b-button>

              <b-button v-else class="btn-cart" :to="{ name: 'shop-checkout', params: $route.params }">
                <feather-icon icon="ShoppingCartIcon" class="mr-50" />
                <span>{{ $t('trip.shop.action.view_in_cart') }}</span>
              </b-button>
            </div>
          </div>
        </b-card>
      </swiper-slide>

      <!-- Add Arrows -->
      <div slot="button-next" class="swiper-button-next" />
      <div slot="button-prev" class="swiper-button-prev" />
    </swiper>
  </b-card>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { fetchMandatoryPackageRequest } from '@/request/globalApi/requests/apiRequests'
import Cart from '@/models/Cart'

export default {
  name: 'MandatoryPackage',

  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      products: [],
      mandatorySelectedIndex: null,
      param: {
        page: 1,
        getAllItems: true,
        nameOrder: 'asc',
        searchQuery: null,
        categoriesId: [],
      },
      tripData: {},
      tripLeg: null,
      cart: new Cart(),
      swiperOptions: {
        slidesPerView: 4,
        spaceBetween: 10,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        breakpoints: {
          1600: {
            slidesPerView: 4,
            spaceBetween: 10,
          },
          1300: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        },
      },
    }
  },
  mounted() {
    this.APIFetchMandatoryPackage()
  },
  methods: {
    APIFetchMandatoryPackage() {
      const organizationId = Number(this.$route.params.provider_id)
      const tripId = this.$route.params.event_id
      fetchMandatoryPackageRequest(tripId, organizationId, this.param)
        .then(r => {
          const { products } = r.data
          this.products = products
          this.mandatorySelectedIndex = products.findIndex(mp => mp.selected)
          if (this.mandatorySelectedIndex !== -1) {
            this.$emit('update:mandatory-package', true)
          }
        })
        .catch(() => {
          this.products = []
        })
    },
    patchCart(index) {
      const tripStepId = this.$route.params.event_id
      this.cart.patchMandatoryPackage(tripStepId, this.products[index].product.mainVariantId).then(() => {
        this.products = this.products.map(product => ({ ...product, selected: false }))
        this.$emit('update:mandatory-package', true)
        this.products[index].selected = true
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
</style>

<style lang="scss" scoped>
.pricing-basic-value {
  font-size: 1.2rem !important;
}

@import '@core/scss/vue/libs/swiper.scss';
@import '../../../../node_modules/swiper/css/swiper.css';

.ecommerce-application .swiper-responsive-breakpoints.swiper-container .swiper-slide {
  padding: 0 !important;
}

.swiper-container {
  height: 100%;
}
.swiper-container .swiper-slide {
  height: auto !important;
}
.swiper-slide .card {
  height: 95% !important
}

.card {
  margin-bottom: 0 !important;
}
.swiper-button-next, .swiper-button-prev {
  width: 40px;
  height: 40px;
  background-color: rgba(236, 236, 236, 0.75) !important;
  border-radius: 50% !important;
}
</style>
