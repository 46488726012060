<template>
  <section class="row">
    <!-- TODO -->
    <!-- <b-col v-if="false" cols="12">
      <b-alert class="m-0" show variant="primary">
        <div class="alert-body">
          <span>{{ $t('alert.product.no_products_for_provider') }}</span>
        </div>
      </b-alert>
    </b-col> -->
    <!-- v-else -->
    <div class="ecommerce-application match-height col-12" style="height: inherit">
      <!-- Sidebar -->
      <ShopListLeftFilterSidebar
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        :params="params"
        :load-products="loadProducts"
        @categorieSelected="params.categoriesIds = $event"
      />
      <!-- ECommerce Header -->
      <section id="ecommerce-header">
        <div class="row">
          <div class="col-sm-12">
            <div class="ecommerce-header-items py-1">
              <div class="result-toggler">
                <feather-icon class="d-block d-lg-none" icon="MenuIcon" size="21" @click="mqShallShowLeftSidebar = true" />
                <div class="search-results">{{ totalItems }} {{ $tc('trip.shop.result_found', totalItems) }}</div>
              </div>

              <!-- TODO -->
              <div v-if="false" class="view-options d-flex">
                <!-- Item View Radio Button Group  -->
                <b-form-radio-group v-model="itemView" button-variant="outline-primary" buttons class="ml-1 pb-1 list item-view-radio-group" size="sm">
                  <b-form-radio v-for="option in itemViewOptions" :key="option.value" :value="option.value">
                    <feather-icon :icon="option.icon" size="18" />
                  </b-form-radio>
                </b-form-radio-group>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- Overlay -->
      <div class="body-content-overlay" />

      <!-- Searchbar -->
      <div class="ecommerce-searchbar">
        <b-row>
          <b-col cols="12">
            <b-input-group class="input-group-merge">
              <b-form-input v-model="params.searchQuery" :placeholder="$t('action.search')" class="search-product" debounce="500" />
              <b-input-group-append is-text>
                <feather-icon class="text-muted" icon="SearchIcon" />
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
      </div>

      <!-- Products -->
      <div class="row">
        <div class="col-sm-12">
          <b-overlay :show="loadProducts" spinner-medium>
            <section :class="itemView">
              <b-card v-for="(productEntity, index) in products" :key="index" class="ecommerce-card" no-body>
                <div class="text-center pt-0">
                  <b-img
                    :alt="`${productEntity.product.name[userLang]}-${productEntity.product.id}`"
                    :src="resolveProductImgScrc(productEntity.product)"
                    class="shop-img"
                    @click="goToShopServiceDetails(productEntity)"
                  />
                </div>

                <!-- Product Details -->
                <b-card-body>
                  <h4 class="item-name text-underline">
                    <b-link
                      :to="{
                        name: 'shop-service-details',
                        params: {
                          product_id: productEntity.product.id,
                          provider_id: $route.params.provider_id,
                          trip_id: $route.params.trip_id,
                          step_id: $route.params.step_id,
                          event_id: $route.params.event_id,
                          airport_id: $route.params.airport_id,
                        },
                      }"
                    >
                      {{ productEntity.product.name | trans }}
                    </b-link>
                  </h4>
                  <p v-if="productEntity.product.mainCategory && productEntity.product.mainCategory.name">
                    {{ productEntity.product.mainCategory.name | trans }}
                  </p>
                  <b-card-text v-if="!productEntity.product.mainVariant.onQuote && productEntity.product.minPrice && productEntity.product.minPrice.displayPriceExcludingTaxes" variant="primary">
                    {{ $tc('pricing.unit_price_before_tax', 1) }} : {{ productEntity.product.minPrice.displayPriceExcludingTaxes | priceFormat }}
                  </b-card-text>
                  <b-card-text v-else>
                    {{ $t('trip.shop.price_on_quote') }}
                  </b-card-text>
                  <b-card-text v-if="productEntity.product.mainVariant && productEntity.product.mainVariant.packing">
                    <p v-if="productEntity.product.mainVariant.packing.pricingType" class="py-0">
                      {{ $t('service.prices.type') }} : {{ productEntity.product.mainVariant.packing.pricingType | enumTranslate('pricing_type') }}
                    </p>
                    <p v-if="productEntity.product.mainVariant.packing.name" class="py-0">
                      {{ $t('service.prices.type_measurement') }} : {{ productEntity.product.mainVariant.packing.name | trans }}
                    </p>
                  </b-card-text>
                  <b-card-text v-if="productEntity.product.description" class="item-description">
                    {{ productEntity.product.description | trans }}
                  </b-card-text>
                </b-card-body>

                <!-- Product Actions -->
                <div class="item-options">
                  <b-button v-if="!productEntity.selected" class="btn-cart" @click="patchCart(productEntity)">
                    <feather-icon class="mr-50" icon="ShoppingCartIcon" />
                    <span>{{ $t('trip.shop.add_to_cart') }}</span>
                  </b-button>

                  <b-button
                    v-else
                    :to="{
                      name: 'shop-checkout',
                      params: {
                        provider_id: productEntity.product.organization.id,
                        trip_id: $route.params.trip_id,
                        step_id: $route.params.step_id,
                        event_id: $route.params.event_id,
                        airport_id: $route.params.airport_id,
                      },
                    }"
                    class="btn-cart "
                  >
                    <feather-icon class="mr-50" icon="ShoppingCartIcon" />
                    <span>{{ $t('trip.shop.action.view_in_cart') }}</span>
                  </b-button>
                </div>
              </b-card>
            </section>
          </b-overlay>
        </div>
      </div>

      <!-- Pagination -->
      <section v-if="products && products.length">
        <b-row>
          <b-col cols="12">
            <b-pagination
              v-model="params.page"
              :per-page="params.numberOfItemsPerPage"
              :total-rows="totalItems"
              align="center"
              first-number
              last-number
              next-class="next-item"
              prev-class="prev-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </section>

      <section v-if="products && products.some(el => el.selected) || mandatorySelected !== null">
        <b-row>
          <b-col class="d-flex justify-content-end" cols="12">
            <b-button
              :to="{
                name: 'shop-checkout',
                params: {
                  provider_id: products[0].product.organization.id,
                  trip_id: $route.params.trip_id,
                  step_id: $route.params.step_id,
                  event_id: $route.params.event_id,
                  airport_id: $route.params.airport_id,
                },
              }"
              class="btn-cart"
              tag="a"
            >
              <span>{{ $tc('trip.shop.action.service_selected_next', products.length) }}</span>
              <feather-icon class="ml-50" icon="ChevronRightIcon" />
            </b-button>
          </b-col>
        </b-row>
      </section>
    </div>
  </section>
</template>

<script>
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import { fetchOrganizationProductsRequest } from '@/request/globalApi/requests/apiRequests'
import Cart from '@/models/Cart'
import { mapState } from 'vuex'
import ShopListLeftFilterSidebar from './ShopListLeftFilterSidebar.vue'

export default {
  name: 'ShopList',

  components: {
    ShopListLeftFilterSidebar,
  },
  props: {
    provider: {
      type: Object,
      default: null,
    },
    mandatorySelected: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      tripData: {},
      cart: new Cart(),
      tripLeg: null,
      products: [],
      loadProducts: false,
      itemView: 'grid-view',
      itemViewOptions: [
        { icon: 'GridIcon', value: 'grid-view' },
        { icon: 'ListIcon', value: 'list-view' },
      ],
      params: {
        // PAGE
        numberOfItemsPerPage: 12,
        page: 1,
        // SORTING
        nameOrder: null,
        // SEARCH
        searchQuery: null,
        categoriesIds: null,
      },
      totalItems: null,
    }
  },
  computed: {
    ...mapState('shop', ['categoriesSelected']),
    userLang() {
      return this.$store.state.appConfig.lang
    },
    dataMeta() {
      const localItemsCount = this.products.length
      return {
        from: this.params.numberOfItemsPerPage * (this.params.page - 1) + (localItemsCount ? 1 : 0),
        to: this.params.numberOfItemsPerPage * (this.params.page - 1) + localItemsCount,
        of: this.totalItems,
      }
    },
    allFilters() {
      return {
        numberOfItemsPerPage: this.params.numberOfItemsPerPage,
        page: this.params.page,
        getAllItems: this.params.getAllItems,
        // SORTING
        nameOrder: this.params.nameOrder,
        // SEARCH
        searchQuery: this.params.searchQuery,
        categoriesIds: this.params.categoriesIds,
        cart: this.cart,
      }
    },
  },
  watch: {
    allFilters() {
      if (this.tripLeg) this.APIFetchProducts()
    },
  },
  mounted() {
    this.params.categoriesIds = this.categoriesSelected
    this.APIFetchTrip()
  },
  methods: {
    updateFiltersCategories(category) {
      this.$store.commit('shop/UPDATE_CATEGORIES_SELECTED', category)
    },
    APIFetchProducts() {
      console.log('fetch products')
      this.loadProducts = true
      this.products = []
      const organizationId = Number(this.$route.params.provider_id)
      fetchOrganizationProductsRequest(this.tripLeg.id, organizationId, this.params)
        .then(r => {
          const { totalItems, products } = r.data
          this.products = products
          this.totalItems = totalItems
        })
        .catch(() => {
          this.products = []
        })
        .finally(() => {
          this.loadProducts = false
        })
    },
    async APIFetchTrip() {
      const tripId = this.$route.params.trip_id
      try {
        this.tripData = await this.$store.dispatch('trip/fetchTrip', { tripId, forced: false })
        this.tripData.tripSteps.forEach(tripStep => {
          this.getCurrentTripStep(tripStep)
        })
        this.APIFetchProducts()
      } catch (error) {
        console.warn(error)
      }
    },
    getCurrentTripStep(tripStep) {
      if (tripStep.flightDeparture && tripStep.flightDeparture.id === parseInt(this.$route.params.event_id, 10)) {
        this.tripLeg = tripStep.flightDeparture
      } else if (tripStep.flightArrival && tripStep.flightArrival.id === parseInt(this.$route.params.event_id, 10)) {
        this.tripLeg = tripStep.flightArrival
      }
    },
    patchCart(productEntity) {
      const tripStepId = this.$route.params.event_id
      if (productEntity.product.className === 'CoreProduct') {
        this.cart.patch(tripStepId, productEntity.product.mainVariant.id, 1).then(() => {
          // eslint-disable-next-line no-param-reassign
          productEntity.selected = true
        })
      }

      if (productEntity.product.className === 'PackageProduct') {
        this.cart.patchMandatoryPackage(tripStepId, productEntity.product.mainVariant.id, 1).then(() => {
          // eslint-disable-next-line no-param-reassign
          productEntity.selected = true
        })
      }
    },
    goToShopServiceDetails(productEntity) {
      this.$router.push({
        name: 'shop-service-details',
        params: {
          product_id: productEntity.product.id,
          provider_id: this.$route.params.provider_id,
          trip_id: this.$route.params.trip_id,
          step_id: this.$route.params.step_id,
          event_id: this.$route.params.event_id,
          airport_id: this.$route.params.airport_id,
        },
      })
    },
    resolveProductImgScrc(product) {
      if (product?.mainVariant?.files?.length) return product.mainVariant.files[0]?.fileUrls[300]
      if (product?.mainCategory?.iconFileUrl) return product.mainCategory.iconFileUrl
      return require('@/assets/images/logo/logo.png')
    },
  },
  setup() {
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
    return {
      mqShallShowLeftSidebar,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce.scss';
.list-view .shop-img {
  height: 7rem;
}
</style>

<style lang="scss" scoped>
.ecommerce-application .grid-view .ecommerce-card .item-img {
  display: initial;
}
.shop-img {
  width: 100%;
  height: 8rem;
  object-fit: contain;
}

.text-body {
  font-size: 1.2rem;
}

.text-price {
  font-size: 1rem;
}

.badge-price {
  position: absolute;
  right: 8px;
  top: 8px;
}

.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
</style>
