<template>
  <section class="row">
    <div class="ecommerce-application match-height col-12" style="height: inherit">
      <!-- ECommerce Header -->
      <section id="ecommerce-header">
        <div class="row">
          <div class="col-sm-12">
            <div class="ecommerce-header-items py-1">
              <div class="result-toggler">
                <feather-icon class="d-block d-lg-none" icon="MenuIcon" size="21" />
                <div class="search-results">{{ totalItems }} {{ $tc('trip.shop.result_found', totalItems) }}</div>
              </div>
              <div v-if="false" class="view-options d-flex">
                <!-- Item View Radio Button Group  -->
                <b-form-radio-group v-model="itemView" button-variant="outline-primary" buttons class="ml-1 pb-1 list item-view-radio-group" size="sm">
                  <b-form-radio v-for="option in itemViewOptions" :key="option.value" :value="option.value">
                    <feather-icon :icon="option.icon" size="18" />
                  </b-form-radio>
                </b-form-radio-group>
              </div>
            </div>
          </div>
        </div>
      </section>

      <hangar-search v-if="tripStep && tripStep.id" :loading="tripStepLoading" :search-loading="loadHangar" :trip-step-event="tripStep" @end="val => (paramsHangar.endAt = val)" @start="val => (paramsHangar.startAt = val)" @click:search-hangar="searchHangar()" />

      <b-overlay :show="showHangar" class="my-3" spinner-medium>
        <b-row v-if="!products.length">
          <b-col cols="12">
            <b-alert class="m-0" show variant="primary">
              <div class="alert-body text-center">
                <span>{{ $t('alert.hangar.no_hangar_found') }}</span>
              </div>
            </b-alert>
          </b-col>
        </b-row>

        <b-row v-else>
          <!-- Overlay -->
          <div class="body-content-overlay" />

          <!-- Products -->
          <div class="row">
            <div class="col-sm-12">
              <b-overlay :show="loadHangar" spinner-medium>
                <section :class="itemView">
                  <b-card v-for="(productEntity, index) in products" :key="index" class="ecommerce-card" no-body>
                    <div class="text-center pt-0">
                      <b-img
                        :alt="`${productEntity.product.name | trans}-${productEntity.product.id}`"
                        :src="
                          productEntity.product.mainVariant.files.length
                            ? productEntity.product.mainVariant.files[0].fileUrls[300]
                            : require('@/assets/images/logo/logo.png')
                        "
                        class="shop-img"
                      />
                    </div>

                    <!-- Product Details -->
                    <b-card-body>
                      <h6 class="text-underline">
                        <b-link
                          :to="{
                            name: 'hangar-detail',
                            params: {
                              product_id: productEntity.product.id,
                              provider_id: $route.params.provider_id,
                              trip_id: $route.params.trip_id,
                              step_id: $route.params.step_id,
                              event_id: $route.params.event_id,
                              airport_id: $route.params.airport_id,
                            },
                            query: {
                              start_at: paramsHangar.startAt,
                              end_at: paramsHangar.endAt,
                            },
                          }"
                        >
                          {{ productEntity.product.name | trans }}
                        </b-link>

                        <b-card-text>
                          <b-link
                            :to="{
                              name: 'provider-details',
                              params: {
                                provider_id: productEntity.product.organization.id,
                                trip_id: $route.params.trip_id,
                                step_id: $route.params.step_id,
                                event_id: $route.params.event_id,
                                airport_id: $route.params.airport_id,
                              },
                            }"
                          />
                        </b-card-text>
                      </h6>
                      <p v-if="productEntity.product.mainCategory && productEntity.product.mainCategory.name">
                        {{ productEntity.product.mainCategory.name | trans }}
                      </p>

                      <b-card-text v-if="productEntity.product.minPrice && productEntity.product.mainVariant.calendarMode">
                        {{ $tc('pricing.total_ex_tax_price', 1) }} : {{ productEntity.product.minPrice.displayPriceExcludingTaxes | priceFormat }}
                      </b-card-text>
                      <b-card-text v-if="productEntity.product.minPrice && !productEntity.product.mainVariant.calendarMode">
                        {{ $tc('pricing.total_ex_tax_price', 1) }} : {{ productEntity.product.minPrice.formattedPriceExcludingTaxes | priceFormat }}
                      </b-card-text>
                      <b-card-text v-if="!productEntity.product.minPrice">
                        {{ $tc('pricing.total_ex_tax_price', 1) }} : {{ $t('trip.shop.price_on_quote') }}
                      </b-card-text>
                      <b-card-text v-if="productEntity.product.description" class="item-description">
                        {{ productEntity.product.description | trans }}
                      </b-card-text>
                    </b-card-body>

                    <!-- Product Actions -->
                    <div class="item-options text-center">
                      <div class="item-wrapper">
                        <div class="item-cost">
                          <h4 class="item-price">
                            <span v-if="!productEntity.product.minPrice">
                              {{ $t('trip.shop.price_on_quote') }}
                            </span>
                            <span v-else>
                              {{ productEntity.product.minPrice.displayPriceExcludingTaxes | priceFormat }} {{ $t('common.excluding_taxes.abbreviation') }}
                            </span>
                          </h4>
                        </div>
                      </div>

                      <b-button
                        :to="{
                          name: 'hangar-detail',
                          params: {
                            product_id: productEntity.product.id,
                            provider_id: $route.params.provider_id,
                            trip_id: $route.params.trip_id,
                            step_id: $route.params.step_id,
                            event_id: $route.params.event_id,
                            airport_id: $route.params.airport_id,
                          },
                          query: {
                            start_at: paramsHangar.startAt,
                            end_at: paramsHangar.endAt,
                          },
                        }"
                        class="btn-cart"
                      >
                        <span>{{ $t('trip.shop.view_details') }}</span>
                      </b-button>
                    </div>
                  </b-card>
                </section>
              </b-overlay>
            </div>
          </div>

          <!-- Pagination -->
          <section v-if="products.length">
            <b-row>
              <b-col cols="12">
                <b-pagination
                  v-model="params.page"
                  :per-page="params.perPage"
                  :total-rows="totalItems"
                  align="center"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </section>
        </b-row>
      </b-overlay>
    </div>
  </section>
</template>

<script>
import Cart from '@/models/Cart'
import number from '@/mixins/number'

import { fetchHangarProductRequest } from '@/request/globalApi/requests/apiRequests'
import HangarSearch from '@/views/provider/hangar/HangarSearch.vue'

export default {
  name: 'HangarList',

  components: {
    HangarSearch,
  },
  mixins: [number],
  props: {
    provider: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      tripData: {},
      tripStep: null,
      tripStepLoading: true,
      products: {},
      loadHangar: false,
      showHangar: false,
      itemView: 'grid-view',
      itemViewOptions: [
        { icon: 'GridIcon', value: 'grid-view' },
        { icon: 'ListIcon', value: 'list-view' },
      ],
      params: {
        // PAGE
        numberOfItemsPerPage: 10,
        page: 1,
        // SORTING
        nameOrder: null,
        // SEARCH
        searchQuery: null,
        categoriesIds: null,
      },
      paramsHangar: {
        organizationId: null,
        // PAGE
        numberOfItemsPerPage: 10,
        page: 1,
        // SORTING
        nameOrder: null,
        // SEARCH
        searchQuery: null,
        categoriesIds: null,
        startAt: '',
        endAt: '',
        selectedOrganizationId: 0,
      },
      perPageOptions: [5, 10, 25, 50, 100],
      sortBy: '',
      sortDesc: '',
      totalItems: null,
    }
  },
  computed: {
    dataMeta() {
      const localItemsCount = this.products.length
      return {
        from: this.params.numberOfItemsPerPage * (this.params.page - 1) + (localItemsCount ? 1 : 0),
        to: this.params.numberOfItemsPerPage * (this.params.page - 1) + localItemsCount,
        of: this.totalItems,
      }
    },
    allFilters() {
      return {
        organizationId: Number(this.$route.params.provider_id),
        numberOfItemsPerPage: this.params.numberOfItemsPerPage,
        page: this.params.page,
        getAllItems: this.params.getAllItems,
        // SORTING
        nameOrder: this.params.nameOrder,
        // SEARCH
        searchQuery: this.params.searchQuery,
        categoriesIds: this.params.categoriesIds,
        cart: new Cart(),
      }
    },
  },
  watch: {
    allFilters: {
      handler() {
        this.APIFetchProducts()
      },
      deep: true,
    },
  },
  mounted() {
    this.APIFetchTrip()
  },
  methods: {
    searchHangar() {
      this.showHangar = true

      this.APIFetchHangar()
    },
    APIFetchHangar() {
      this.loadHangar = true
      fetchHangarProductRequest(this.tripStep.id, this.paramsHangar)
        .then(r => {
          const { products } = r.data
          this.products = products
        })
        .catch(() => {
          this.products = undefined
        })
        .finally(() => {
          this.loadHangar = false
          this.showHangar = false
        })
    },
    async APIFetchTrip() {
      const tripId = this.$route.params.trip_id
      try {
        this.tripData = await this.$store.dispatch('trip/fetchTrip', { tripId, forced: false })
        this.tripData.tripSteps.forEach(tripStep => {
          if (tripStep.flightDeparture && tripStep.flightArrival) {
            if (!this.getCurrentTripStep(tripStep.flightDeparture)) {
              this.getCurrentTripStep(tripStep.flightArrival)
            }
          }
        })
      } catch (error) {
        console.error(error)
      }
    },
    // TODO: refacto
    getCurrentTripStep(leg) {
      let currentTripStep = null
      Object.entries(leg).forEach(([key, val]) => {
        if (key === 'id' && val === parseInt(this.$route.params.event_id, 10)) {
          currentTripStep = leg
          this.tripStep = currentTripStep
          this.tripStepLoading = false
          this.paramsHangar.startAt = currentTripStep.endsAt
          this.paramsHangar.endAt = currentTripStep.endsAt
          this.paramsHangar.organizationId = Number(this.$route.params.provider_id)
          return true
        }
        return false
      })
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce.scss';
.list-view .shop-img {
  height: 7rem;
}
</style>
<style lang="scss" scoped>
.ecommerce-application .grid-view .ecommerce-card .item-img {
  display: initial;
}
.shop-img {
  width: 100%;
  height: 15rem;
  object-fit: contain;
}

.badge-price {
  position: absolute;
  right: 8px;
  top: 8px;
}

.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}

.text-body {
  font-size: 1.2rem;
}

</style>
